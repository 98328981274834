import './article.css'

const article2 = () => {

    return (

        <div className='articleDiv'>

            <h1 className='articleTitle'>Début de mon aventure Freelance</h1>
            <div className='articleBody'>
                <div className='articleBodyText'>
                    <img src="images/articles/article2.webp" className='articleBodyImage' />

                    Durant la crise du Covid j’ai dû mettre mes études d’ingénierie logiciel en pause, j’en ai profité pour continuer de me former de mon côté et je me suis mis pour objectif de me lancer en Freelance. En 2020, et malgré la crise, le marché de la création d’applications mobile était déjà en plein essor, je décidai donc de me former sur une technologie mobile : React Native, ce fut par la suite un pari gagnant. Je me suis donc formé à la programmation mobile afin de pouvoir réaliser des missions de création d’application. Voici une de mes réalisations :
                    <br />
                    <br />

                    <h2 className='bold mb-2 mt-4'>Présentation de l'application Bon et Facture</h2>
                    Bon et Facture est une application mobile à destination des professionnels et entreprises qui cherchent à rationaliser leur processus de gestion de facturation. L’application permet de créer et gérer des factures et bons de commande. Tous les documents générés sont conformes à la législation française. Bon et Facture calcule également les différentes taxes et TVA en vigueur. Une fois les documents créés, l’utilisateur a la possibilité de les envoyer par mail et de les télécharger sous format PDF. Bon et Facture contient un module d’authentification (connexion/inscription) avec email/mot de passe ou via un compte Google. L’application a également une version « Premium » qui permet à l’utilisateur de créer un nombre illimité de documents.
                    <br />

                    <h2 className='bold mb-2 mt-4'>Développement et Technologies Utilisées</h2>
                    J’ai travaillé sur tous les aspects de cette application, du back (Google Functions) au front (React Native) en passant par la gestion des données (Firebase) et l’hébergement/maintenance. Ce fut un des premiers gros projets dans lequel j’utilisais React Native et Firebase. J’ai su mettre en place une infrastructure sécurisée pour les utilisateurs en appliquant les bonnes pratiques des technologies utilisées dans le projet. Pour le côté back-end, j’ai décidé d’utiliser Google Functions car ce service cloud est étroitement lié à Firebase et permet ainsi une meilleure synchronicité. Le front-end a été développé en React Native, ce qui s'est avéré être un choix judicieux à la fois pour sa simplicité d'utilisation et sa capacité à être compatible à la fois sur iOS et Android. C'était également un bon choix pour se lancer en Freelance dans le développement mobile, étant donné que cela offre une expertise recherchée sur le marché et une polyvalence pour répondre aux besoins des clients sur différentes plateformes. J’ai souhaité utiliser Firebase afin de ne pas perdre du temps à configurer et mettre en place une base de données plus conséquente (tel que MongoDB ou PostgreSQL). Firebase est assez simple à initialiser et scalable, ce qui est parfait pour le projet.
                    <br />

                    <h2 className='bold mb-2 mt-4'>Défis et Apprentissage</h2>
                    La publication sur les stores était nouvelle pour moi. L'un des défis auxquels j'ai été confronté était de respecter les normes de Google Play et de l'App Store d'Apple. Heureusement pour moi, un ami travaillait déjà avec React Native et m'a offert son aide après lui avoir fait part des difficultés que je rencontrais. Grâce à son expertise, j'ai pu surmonter les obstacles techniques. Ensemble, nous avons transformé les complications en opportunités et mon application est désormais prête à être découverte par le monde entier. Je lui suis vraiment reconnaissant pour son soutien précieux.
                    <br />
                    <br />
                    La sécurisation des données Firebase était aussi un défi pour moi. Je me suis rendu compte que n’importe quel utilisateur de mon application avait accès à la totalité des données, cachées ou non, de mon projet. J’ai décidé de suivre un cours en ligne sur la sécurisation de Firebase, en plus de consulter la documentation officielle, afin de comprendre en profondeur les meilleures pratiques et de garantir la protection des données de mon application. Cela m'a incité à développer le désir d'apprendre à rechercher et à respecter les meilleures pratiques de chaque technologie que j'explorais. J'ai réalisé à quel point il est important de rester informé des dernières avancées en matière de sécurité informatique.
                    <br />
                    <br />
                    <h2 className='bold mb-2 mt-4'></h2>
                    Bon et Facture est aujourd’hui disponible sur le <a href='https://play.google.com/store/apps/details?id=com.bonsetfactures&hl=fr'>store Google </a> et m’a permis de gagner en expérience dans mon activité de freelance.

                </div>

            </div>
            <div className='articleProjectLink'>
                <div onClick={() => window.location.replace('/projects')}>Projet Bon & Facture</div>
            </div>
        </div>
    )
}
export default article2

import React, { useState, useEffect, useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import { Container, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Fade from 'react-reveal';
import Header from './Header';
import endpoints from '../constants/endpoints';
import FallbackSpinner from './FallbackSpinner';
import { useHistory } from 'react-router-dom';
import * as articlesList from '../articles/index.js'
import '../css/article.css';
import AppContext from '../AppContext.js';

const styles = {
    introTextContainer: {
        margin: 10,
        flexDirection: 'column',
        whiteSpace: 'pre-wrap',
        textAlign: 'left',
        fontSize: '1.2em',
        fontWeight: 500,
    },
    introImageContainer: {
        margin: 10,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
};

function Article() {
    const history = useHistory();
    const { darkMode } = useContext(AppContext);
    const id = Number(window.location.search.split("=")[1])
    const [data, setData] = useState(null);


    useEffect(() => {
        fetch(endpoints.blog, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((res) => {
                setData(res.articles[id])
            })
            .catch((err) => err);
    }, []);


    //
    const returButton = () => {
        return (
            <div className={darkMode.value ? 'returnBlogButton darkBlogBtn' : 'returnBlogButton whiteBlogBtn'} onClick={() => history.push('/blog')}>
                <p className='returnBlogButtonText '>Retour au blog</p>

            </div>
        )
    }

    if (data == null) return (<>
        {returButton()}
        <FallbackSpinner />
    </>)
    return (
        <div className='articleDiv' >
            {returButton()}
            {articlesList["article" + id]()}
        </div >
    );
}

export default Article;

import './article.css'

const article5 = () => {

    return (

        <div className='articleDiv'>

            <h1 className='articleTitle'>Communication Efficace : Une Compétence Clé pour le Développeur</h1>
            <div className='articleBody'>
                <div className='articleBodyText'>
                    <img src="images/articles/article5.webp" className='articleBodyImage' />

                    Dans le monde de l'informatique, la communication efficace est une compétence non technique essentielle souvent sous-estimée. Durant mon expérience en alternance chez Milango.io et dans mes missions en freelance, j'ai réalisé à quel point une communication claire et cohérente était cruciale pour la réussite des projets. Voici comment cette compétence s'est manifestée dans mon parcours et pourquoi elle est vitale pour tout développeur.
                    <br />
                    <br />

                    <h2 className='bold mb-2 mt-4'>Rôle chez Milango.io</h2>
                    Chez Milango.io, mon rôle comprenait des tâches techniques comme le développement BackEnd et la mise en place d'infrastructures cloud, ainsi que des interactions fréquentes avec divers intervenants, notamment les clients, les membres de l'équipe, et les partenaires externes. La communication efficace a été le fil conducteur qui m'a permis de naviguer avec succès à travers ces différentes interactions.
                    <br />
                    <br />

                    <h2 className='bold mb-2 mt-4'>Comprendre les Exigences des Clients</h2>
                    Lors de la création et du développement d'une structure BackEnd évolutive et sécurisée, il était impératif de comprendre les exigences des clients et de les traduire en spécifications techniques. J'ai appris à poser les bonnes questions et à écouter activement pour m'assurer que toutes les attentes étaient clairement définies. Cette compréhension mutuelle a évité des malentendus coûteux et a permis d'aligner les objectifs de tous les partis.
                    <br />
                    <br />

                    <h2 className='bold mb-2 mt-4'>Documentation et Collaboration</h2>
                    La documentation complète de l'API interne a également été un exercice de communication. Une documentation bien rédigée est essentielle pour garantir que les autres développeurs et les futurs collaborateurs puissent comprendre et utiliser le code sans ambiguïté. J'ai pris soin de détailler chaque fonction, chaque paramètre, et chaque interaction avec des services externes comme les API de Stripe, MondialRelay, et Chronopost. Cela a non seulement aidé à maintenir la cohérence du projet, mais a également facilité la collaboration et le transfert de connaissances.
                    <br />
                    <br />

                    <h2 className='bold mb-2 mt-4'>Réunions et Mises à Jour de Projet</h2>
                    Les réunions d'équipe régulières et les mises à jour de projet ont été des occasions clés pour pratiquer une communication efficace. Partager les progrès, les défis et les solutions de manière claire et concise a permis de maintenir tout le monde sur la même longueur d'onde. Utiliser des outils de gestion de projet comme Trello et Asana a également aidé à visualiser les tâches et à assurer la transparence sur l'avancement des projets.
                    <br />
                    <br />

                    <h2 className='bold mb-2 mt-4'>Feedback Constructif</h2>
                    Un autre aspect crucial de la communication efficace est la capacité à donner et à recevoir des feedbacks constructifs. Chez Milango.io, nous avons cultivé une culture où les critiques constructives étaient encouragées. Cela m'a permis d'améliorer continuellement mes compétences et de contribuer de manière positive à l'équipe. Apprendre à formuler des feedbacks de manière respectueuse et utile a renforcé notre collaboration et a stimulé l'innovation.
                    <br />
                    <br />

                    <h2 className='bold mb-2 mt-4'>Mission Freelance pour Qorner</h2>
                    Ma mission freelance pour Gabriel Puig chez Qorner est un excellent exemple de l'importance de la communication efficace. Cette mission impliquait l'ajout de plusieurs fonctionnalités à trois applications distinctes (SuperAdmin, Admin et Mobile Client), ainsi que des modifications de la base de données et le déploiement des applications sur les stores iOS et Android.

                    Gabriel avait des attentes précises concernant les nouvelles fonctionnalités, telles que l'intégration de Google Maps, des filtres d'affichage, des graphiques de statistiques, et des systèmes de modération. La communication claire et constante était essentielle pour s'assurer que chaque aspect de la mission répondait exactement à ses besoins. En discutant régulièrement des progrès, en expliquant les choix techniques et en répondant rapidement à ses questions et préoccupations, j'ai pu garantir que le projet avançait sans heurts et que les résultats étaient alignés avec ses attentes.
                    <br />
                    <br />

                    <h2 className='bold mb-2 mt-4'>Témoignages de Satisfaction</h2>
                    Gabriel Puig a exprimé sa satisfaction à travers ses témoignages :
                    <br />
                    "Seconde mission confiée à Abdallah, et toujours aussi satisfait de son travail. Réactif, disponible, Abdallah a une bonne analyse des problématiques et en assure un traitement efficace. Je recommande." - Gabriel, Qorner

                    "Efficace et réactif, Abdallah a su rapidement déboguer les contraintes techniques rencontrées. C'est avec sérénité que je pourrais envisager de le solliciter à nouveau sur de nouvelles missions." - Gabriel, Qorner

                    Ces témoignages soulignent l'importance de la communication efficace dans le succès des missions freelance. Être capable de comprendre les besoins du client, de fournir des mises à jour régulières et de répondre rapidement aux problèmes techniques a été crucial pour maintenir une relation de confiance et garantir la satisfaction du client.
                    <br />
                    <br />

                    <h2 className='bold mb-2 mt-4'></h2>
                    En conclusion, la communication efficace est une compétence non technique essentielle pour tout développeur. Mon expérience en alternance chez Milango.io et mes missions freelance m'ont permis de voir à quel point une communication claire et cohérente peut influencer positivement la réussite des projets. Que ce soit pour comprendre les besoins des clients, documenter les processus techniques, collaborer avec l'équipe, ou interagir avec des partenaires externes, la communication est au cœur de chaque étape du développement de logiciels. En continuant à affiner cette compétence, je suis convaincu de pouvoir contribuer de manière encore plus significative à mes futurs projets et collaborations.
                </div>

            </div>
            <div className='articleProjectLink'>
                <div onClick={() => window.location.replace('/projects')}>Projet Qorner</div>
                <div onClick={() => window.location.replace('/projects')}>Projet Milango</div>
            </div>
        </div>
    )
}
export default article5

import './article.css'

const article1 = () => {

    return (

        <div className='articleDiv'>

            <h1 className='articleTitle'>Création d'un incubateur d'e-Commerce</h1>
            <div className='articleBody'>
                <div className='articleBodyText'>
                    <img src="images/articles/article1.webp" className='articleBodyImage' />

                    Durant mon alternance, j’ai été au cœur d’un projet d’incubateur d’eCommerce. Ce projet visé à donner à l’utilisateur final la possibilité de créer une boutique en ligne personnalisée en quelques clics.
                    <br />

                    <h2 className='bold mb-2 mt-4'>Rôle et Responsabilités</h2>
                    J’y ai travaillé en tant que développeur back-end dans un premier temps puis en tant que développeur full-stack et devops. J’ai su mettre en place une infrastructure sécurisée pour l’API interne du projet, choisissant quelles technologies utiliser et quel langage adopter. J’ai choisi d’utiliser le langage JavaScript (TypeScript) à la fois pour sa scalabilité et parce que nos équipes étaient déjà familiarisées avec.
                    <br />

                    <h2 className='bold mb-2 mt-4'>Choix des Technologies</h2>
                    J’ai dû ensuite choisir et mettre en place les bases de données. Pour des raisons de coût et de simplicité d’exécution, nous avons choisi d’utiliser MongoDB, qui, nous trouvions, avait un excellent rapport qualité/prix, ainsi qu’un temps d’implémentation relativement court. Pour ce qui a été du déploiement et de l’hébergement, nous avons choisi de combiner AWS et Docker pour des raisons de scalabilité encore une fois. N’ayant jamais travaillé avec AWS, j’ai su me former aux services cloud d’Amazon.
                    <br />

                    <h2 className='bold mb-2 mt-4'>Documentation et CI/CD</h2>
                    J’ai ensuite été responsable de documenter toute la partie backend du projet afin d'ouvrir l’API à d’autres développeurs. On m’a également confié la tâche d’implémenter l’intégration continue, c’était également un domaine que je ne maîtrisais pas mais auquel j’ai su me former assez rapidement afin de donner des résultats plus que satisfaisants, et ainsi faire gagner énormément de temps et de sérénité à toutes les équipes. Enfin, je suis intervenu rapidement sur la partie front pour régler quelques bugs et optimiser le déploiement des fronts dans nos services cloud.
                    <br />

                    <h2 className='bold mb-2 mt-4'>Résultats et Impact</h2>
                    Le développement de ce projet a duré 2 ans et demi et est maintenant un des projets phares de l’entreprise. Aujourd’hui, le produit réalise plusieurs milliers d’euros de chiffre d’affaires et attire de plus en plus de clients.

                </div>

            </div>
            <div className='articleProjectLink'>
                <div onClick={() => window.location.replace('/projects')}>Projet Milango</div>
            </div>
        </div>
    )
}
export default article1

import './article.css'

const article6 = () => {

    return (

        <div className='articleDiv'>

            <h1 className='articleTitle'>Maîtrise des Services Cloud : L'Utilisation d'AWS et d'Autres Technologies Cloud</h1>
            <div className='articleBody'>
                <div className='articleBodyText'>
                    <img src="images/articles/article6.webp" className='articleBodyImage' />

                    Dans le domaine de l'informatique, la maîtrise des services cloud est devenue une compétence essentielle pour tout développeur. Au cours de mon parcours, j'ai eu l'opportunité de travailler avec plusieurs technologies cloud, notamment AWS, Google Functions et Firebase. Ces expériences m'ont permis de développer une expertise solide en matière de déploiement, de gestion et d'optimisation d'infrastructures cloud. Voici un aperçu de mes projets et de la manière dont j'ai utilisé ces technologies pour répondre aux besoins des clients.
                    <br />
                    <br />

                    <h2 className='bold mb-2 mt-4'>Projet chez Milango.io</h2>
                    Lors de mon alternance chez Milango.io, j'étais chargé de la création et du développement d'une structure BackEnd scalable et sécurisée. Pour ce projet, j'ai choisi d'utiliser AWS pour l'hébergement et la gestion des solutions cloud. AWS offre une variété de services robustes qui m'ont permis de construire une architecture performante et évolutive. Parmi les services utilisés, on retrouve EC2 pour les instances de serveur, S3 pour le stockage, et Lambda pour les fonctions serverless.
                    <br />
                    <br />
                    En plus d'AWS, j'ai intégré des services externes tels que Stripe pour les paiements en ligne, et MondialRelay et Chronopost pour les solutions logistiques. La communication efficace avec ces services a été facilitée par l'utilisation de Google Functions, permettant une meilleure synchronisation et une gestion des tâches en arrière-plan.
                    <br />
                    <br />
                    Grâce à cette expérience, j'ai pu évoluer de développeur full stack à un rôle de DevOps. La gestion des infrastructures cloud, l'optimisation des déploiements continus et la maintenance des systèmes m'ont permis d'acquérir des compétences cruciales pour cette transition. Mon travail quotidien consistait non seulement à développer des applications, mais aussi à assurer leur stabilité, leur sécurité et leur scalabilité.
                    <br />
                    <br />

                    <h2 className='bold mb-2 mt-4'>Projet "Bon et Facture"</h2>
                    Pour le projet "Bon et Facture", une application mobile de gestion de factures et de bons de commande, j'ai choisi d'utiliser Firebase pour la gestion des données. Firebase est particulièrement adapté aux applications mobiles grâce à sa facilité de mise en œuvre et à sa scalabilité. J'ai utilisé Firebase Authentication pour gérer les connexions des utilisateurs, Firestore pour la base de données en temps réel, et Firebase Cloud Functions pour les opérations serverless.
                    <br />
                    <br />
                    Le choix de Google Functions pour le back-end a également été stratégique. Ce service cloud est étroitement lié à Firebase, permettant une meilleure synchronisation et une gestion efficace des opérations en arrière-plan. L'utilisation de Firebase m'a permis de déployer rapidement l'application et d'assurer une expérience utilisateur fluide et sécurisée.
                    <br />
                    <br />
                    Cette expérience m'a également permis de renforcer mes compétences en DevOps, en particulier dans la gestion des bases de données et des services cloud. L'implémentation de Firebase et Google Functions a été une étape cruciale pour garantir une performance optimale et une sécurité des données.
                    <br />
                    <br />

                    <h2 className='bold mb-2 mt-4'>Importance des Services Cloud</h2>
                    La maîtrise des services cloud, tels qu'AWS, Google Functions, et Firebase, est essentielle pour répondre aux exigences des projets modernes. Ces technologies permettent de construire des infrastructures évolutives, de gérer les données de manière sécurisée et de déployer des applications rapidement et efficacement. Mon expérience avec ces services m'a permis de répondre aux besoins variés des clients et de garantir la réussite des projets.
                    <br />
                    <br />

                    <h2 className='bold mb-2 mt-4'></h2>
                    En conclusion, l'utilisation des services cloud est un atout majeur pour tout développeur. Grâce à mes expériences chez Milango.io et sur le projet "Bon et Facture", j'ai pu développer une expertise approfondie dans la gestion des infrastructures cloud. Cette compétence m'a non seulement permis de passer de développeur full stack à DevOps, mais aussi de consolider mon parcours professionnel dans cette voie. Je compte continuer à approfondir mes compétences en DevOps, en explorant davantage les technologies cloud et les meilleures pratiques de l'industrie, afin de fournir des solutions robustes et performantes à mes clients.
                    <br />
                    <br />
                </div>
            </div>
            <div className='articleProjectLink'>
                <div onClick={() => window.location.replace('/projects')}>Projet Milango</div>
                <div onClick={() => window.location.replace('/projects')}>Projet Bon et Facture</div>
            </div>
        </div>
    )
}
export default article6

import './article.css'

const article4 = () => {

    return (

        <div className='articleDiv'>

            <h1 className='articleTitle'>La Pensée Critique et la Prise de Décision</h1>
            <div className='articleBody'>
                <div className='articleBodyText'>
                    <img src="images/articles/article4.webp" className='articleBodyImage' />

                    La pensée critique et la prise de décision sont des compétences essentielles dans le domaine de l'informatique, où les choix technologiques peuvent avoir un impact significatif sur le succès des projets. Mon expérience en alternance chez Milango.io a été un terrain d'apprentissage idéal pour développer et affiner ces compétences.
                    <br />
                    <br />

                    <h2 className='bold mb-2 mt-4'>Développement d'une Structure BackEnd</h2>
                    Durant mon alternance, j'ai été chargé de créer et de développer une structure BackEnd évolutive et sécurisée pour une plateforme de marketplace. Ce projet m'a offert l'opportunité de prendre des décisions cruciales concernant les technologies et les méthodologies à adopter.
                    <br />
                    <br />

                    <h2 className='bold mb-2 mt-4'>Choix des Technologies</h2>
                    L'un des premiers défis a été de choisir les technologies les plus appropriées pour construire l'API REST. Après une analyse approfondie des options disponibles, j'ai opté pour ExpressJS et NodeJS. Ces technologies offrent une grande flexibilité et une performance optimale, nécessaires pour une architecture évolutive. La décision était basée sur une évaluation des avantages, des inconvénients et des exigences spécifiques du projet.
                    <br />
                    <br />
                    Pour la gestion des bases de données, j'ai choisi MongoDB. Cette base de données NoSQL est particulièrement adaptée aux applications nécessitant une grande scalabilité et une gestion efficace des données non structurées. La capacité de MongoDB à s'adapter rapidement aux changements de schéma a été un facteur déterminant dans mon choix.
                    <br />
                    <br />
                    La mise en place de l'intégration continue a été un autre aspect crucial du projet. J'ai implémenté une solution basée sur Docker et AWS. Docker permet de créer des environnements de développement cohérents et reproductibles, ce qui est essentiel pour l'intégration continue. AWS, avec ses services cloud robustes, offre une infrastructure fiable et évolutive. Cette décision a nécessité une évaluation rigoureuse des besoins du projet et une compréhension approfondie des avantages offerts par chaque technologie.
                    <br />
                    <br />

                    <h2 className='bold mb-2 mt-4'>Intégration de Services Externes</h2>
                    En plus de ces choix technologiques, j'ai également intégré des services externes à l'application, tels que les API de Stripe pour les paiements en ligne, et MondialRelay et Chronopost pour les solutions logistiques. Chaque intégration nécessitait une compréhension détaillée des API et une évaluation de leur compatibilité avec notre architecture.
                    <br />
                    <br />

                    <h2 className='bold mb-2 mt-4'>Gestion de l'Hébergement et Solutions Cloud</h2>
                    La gestion de l'hébergement et des solutions cloud a été un aspect clé du projet. J'ai utilisé AWS pour héberger l'application, assurant une performance et une disponibilité optimales. Les services AWS comme EC2, S3 et Lambda ont été essentiels pour créer une infrastructure scalable et sécurisée.
                    <br />
                    <br />
                    La documentation complète de l'API interne a été une étape cruciale pour assurer une bonne communication au sein de l'équipe et une maintenance efficace du projet. Une documentation claire et détaillée permet de faciliter l'onboarding des nouveaux développeurs et de garantir la continuité du projet.
                    <br />
                    <br />

                    <h2 className='bold mb-2 mt-4'>Rôle et Contribution</h2>
                    En tant que développeur BackEnd, spécialisé en Node.js, j'ai contribué à la création d'un incubateur de marketplace en assurant la performance et la fiabilité de la plateforme grâce à une architecture robuste et évolutive. Ma polyvalence m'a également permis de collaborer sur le FrontEnd pour offrir une expérience utilisateur intuitive et d'optimiser l'infrastructure cloud sur AWS.
                    <br />
                    <br />
                    Cette expérience en alternance chez Milango.io m'a permis de développer des compétences essentielles en pensée critique et en prise de décision. Chaque choix technologique était le résultat d'une réflexion approfondie et d'une évaluation rigoureuse des options disponibles. Cette approche m'a non seulement aidé à assurer le succès du projet, mais m'a également préparé à relever de futurs défis technologiques avec confiance et expertise.
                    <br />
                    <br />

                    <h2 className='bold mb-2 mt-4'></h2>
                    En conclusion, la pensée critique et la prise de décision sont des compétences indispensables pour tout développeur souhaitant réussir dans le domaine de l'informatique. Mon expérience chez Milango.io a été un exemple concret de l'importance de ces compétences dans la réalisation de projets complexes et innovants. En continuant à développer ces qualités, je suis confiant de pouvoir contribuer de manière significative à tout projet futur et de toujours rester à la pointe de l'innovation technologique.
                    <br />
                    <br />
                </div>

            </div>
            <div className='articleProjectLink'>
                <div onClick={() => window.location.replace('/projects')}>Projet Milango</div>
                <div onClick={() => window.location.replace('/projects')}>Projet Heeroo</div>
            </div>
        </div>
    )
}
export default article4

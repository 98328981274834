import './article.css'

const article8 = () => {

    return (

        <div className='articleDiv'>

            <h1 className='articleTitle'>Maîtrise de l'Intégration et du Déploiement Continu (CI/CD) avec Docker, Git, et AWS</h1>
            <div className='articleBody'>
                <div className='articleBodyText'>
                    <img src="images/articles/article8.webp" className='articleBodyImage' />

                    L'intégration et le déploiement continu (CI/CD) sont des pratiques essentielles pour garantir la qualité, la rapidité et la fiabilité des livrables dans le développement logiciel. Grâce à mon expérience professionnelle, j'ai acquis une expertise significative dans la mise en place de pipelines CI/CD en utilisant Docker, Git, et AWS. Voici un aperçu de mes compétences et de la manière dont j'ai utilisé ces technologies pour améliorer les processus de développement et de déploiement.
                    <br />
                    <br />

                    <h2 className='bold mb-2 mt-4'>Expérience chez Milango.io</h2>
                    Lors de mon alternance chez Milango.io, j'ai été chargé de mettre en place une infrastructure CI/CD pour un incubateur de marketplace. L'objectif était de permettre un déploiement rapide et fiable des nouvelles fonctionnalités et corrections de bugs. Pour ce projet, j'ai utilisé Docker pour créer des environnements de développement cohérents et reproductibles, Git comme système de contrôle de version pour gérer efficacement le flux de travail, et AWS pour héberger nos applications et nos pipelines CI/CD.
                    <br />
                    <br />

                    <h2 className='bold mb-2 mt-4'>Mise en Place du Pipeline CI/CD</h2>
                    La mise en place du pipeline CI/CD comprenait plusieurs étapes clés :
                    <br />
                    <br />
                    - Les développeurs créaient et testaient les nouvelles fonctionnalités sur leurs machines locales en utilisant des conteneurs Docker.
                    <br />
                    - À chaque commit dans le dépôt Git, des hooks déclenchaient une série de tests automatisés pour vérifier que le nouveau code n'introduisait pas de régressions.
                    <br />
                    - Une fois les tests passés, le code était automatiquement déployé sur un environnement de staging sur AWS.
                    <br />
                    <br />
                    Les environnements de staging et de production étaient surveillés en temps réel pour détecter les anomalies et les problèmes de performance.
                    <br />
                    <br />

                    <h2 className='bold mb-2 mt-4'>Avantages de la Mise en Place CI/CD</h2>
                    Les avantages de cette mise en place étaient nombreux : réduction des temps de déploiement, amélioration de la qualité du code grâce aux tests automatisés, et scalabilité et fiabilité accrues grâce à l'utilisation de Docker et d'AWS. Ces pratiques m'ont permis de développer une grande maîtrise de la structure, de la documentation, du développement et de la méthode TDD (Test-Driven Development).
                    <br />
                    <br />

                    <h2 className='bold mb-2 mt-4'></h2>
                    Maîtriser les pratiques de CI/CD avec Docker, Git, et AWS a été un atout majeur dans mon parcours professionnel. Ces technologies m'ont permis de mettre en place des processus de développement et de déploiement efficaces, garantissant la qualité, la rapidité et la fiabilité des livrables. Mon expérience chez Milango.io a renforcé mes compétences en intégration et déploiement continu, en structuration d'architecture backend, et en gestion de l'infrastructure cloud, ce qui m'a beaucoup apporté en tant que développeur.
                    <br />
                </div>
            </div>
            <div className='articleProjectLink'>
                <div onClick={() => window.location.replace('/projects')}>Projet Milango</div>
            </div>
        </div>
    )
}
export default article8

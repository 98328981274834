import './article.css'

const article7 = () => {

    return (

        <div className='articleDiv'>

            <h1 className='articleTitle'>Maîtrise du Développement BackEnd avec Node.js et Express.js</h1>
            <div className='articleBody'>
                <div className='articleBodyText'>
                    <img src="images/articles/article7.webp" className='articleBodyImage' />

                    Le développement BackEnd est une compétence cruciale pour tout développeur souhaitant créer des applications web performantes et évolutives. Mon expertise en Node.js et Express.js a été renforcée par diverses expériences professionnelles, notamment chez Milango.io et à travers des projets freelance. Voici un aperçu de mes compétences et de la manière dont j'ai utilisé ces technologies pour réaliser des projets réussis.
                    <br />
                    <br />

                    <h2 className='bold mb-2 mt-4'>Expérience chez Milango.io</h2>
                    Lors de mon alternance chez Milango.io, j'étais responsable de la création et du développement d'une structure BackEnd évolutive et sécurisée. J'ai choisi d'utiliser Node.js et Express.js pour construire l'API REST du projet. Node.js, avec son environnement d'exécution basé sur le moteur V8 de Chrome, offre des performances élevées et une gestion efficace des E/S non bloquantes, ce qui est essentiel pour des applications web à grande échelle.
                    <br />
                    <br />

                    <h2 className='bold mb-2 mt-4'>Utilisation de Node.js et Express.js</h2>
                    Express.js, un framework minimaliste pour Node.js, m'a permis de structurer mon application de manière modulaire et d'accélérer le développement grâce à ses nombreuses fonctionnalités intégrées. Pour ce projet, j'ai également utilisé MongoDB comme base de données, ce qui s'intègre parfaitement avec Node.js pour gérer les données de manière efficace.
                    <br />
                    <br />

                    <h2 className='bold mb-2 mt-4'>Principales Responsabilités</h2>
                    Les principales responsabilités incluaient :
                    <br />
                    <br />
                    - Création d'une API REST avec Express.js pour gérer les différentes opérations CRUD.
                    <br />
                    - Intégration de services externes comme Stripe pour les paiements en ligne et MondialRelay pour les solutions logistiques.
                    <br />
                    - Mise en place d'un système de gestion des utilisateurs avec authentification sécurisée.
                    <br />
                    - Déploiement de l'application sur AWS pour assurer une disponibilité et une scalabilité optimales.
                    <br />
                    <br />

                    <h2 className='bold mb-2 mt-4'>Méthodologie TDD</h2>
                    Grâce à cette expérience, j'ai acquis une maîtrise approfondie du développement BackEnd, allant de la structuration de l'architecture à la documentation complète des API, en passant par le développement et la mise en œuvre de la méthodologie TDD (Test-Driven Development). Cette méthodologie m'a permis d'assurer la qualité et la fiabilité du code en écrivant des tests automatisés avant de développer les fonctionnalités.
                    <br />
                    <br />

                    <h2 className='bold mb-2 mt-4'>Avantages de Node.js et Express.js</h2>
                    Node.js et Express.js sont des outils puissants pour le développement BackEnd grâce à leurs nombreux avantages :
                    <br />
                    <br />
                    - Performance : Node.js est capable de gérer un grand nombre de connexions simultanées grâce à son modèle asynchrone basé sur des événements.
                    <br />
                    - Scalabilité : La nature non bloquante de Node.js permet de construire des applications hautement scalables.
                    <br />
                    - Flexibilité : Express.js offre une grande flexibilité pour structurer les applications et inclut de nombreuses fonctionnalités intégrées pour accélérer le développement.
                    <br />
                    - Communauté et Écosystème : Node.js et Express.js bénéficient d'une large communauté et d'un écosystème riche en modules et packages, ce qui facilite le développement et la maintenance des applications.
                    <br />
                    <br />

                    <h2 className='bold mb-2 mt-4'></h2>
                    Maîtriser Node.js et Express.js a été un atout majeur dans mon parcours professionnel. Que ce soit pour développer des API REST, intégrer des services externes ou déployer des applications sur des infrastructures cloud, ces technologies m'ont permis de réaliser des projets performants et sécurisés. Mon expérience chez Milango.io et dans mes projets freelance m'a permis de renforcer mes compétences en développement BackEnd, et je suis déterminé à continuer à approfondir mes connaissances dans ce domaine pour fournir des solutions innovantes et efficaces à mes clients.
                    <br />
                </div>
            </div>
            <div className='articleProjectLink'>
                <div onClick={() => window.location.replace('/projects')}>Projet Milango</div>
            </div>
        </div>
    )
}
export default article7

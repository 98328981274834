import './article.css'

const article0 = () => {

    return (

        <div className='articleDiv'>

            <h1 className='articleTitle'>Innovation et Autonomie en Informatique</h1>
            <div className='articleBody'>
                <div className='articleBodyText'>
                    <img src="images/articles/article0.webp" className='articleBodyImage' />

                    Dans le domaine de l'informatique, les changements sont constants et rapides. En tant qu'ingénieur logiciel, je suis quotidiennement confronté à de nouvelles technologies, à des méthodologies émergentes et à des défis inédits. L'évolution rapide de ce secteur exige une capacité d'adaptation et une volonté d'apprendre en continu. Dans cet environnement dynamique, être débrouillard, curieux et autonome n'est pas seulement un atout, mais une nécessité. Ces compétences me permettent de rester à la pointe de l'innovation, de résoudre efficacement des problèmes complexes et de contribuer de manière significative à la réussite de mon entreprise.
                    <br />
                    <br /><h2 className='bold'>L'Importance de la Curiosité et de l'Autonomie</h2>
                    <br />La curiosité me pousse à explorer constamment de nouvelles technologies et à rester informé des tendances du secteur. L'autonomie se traduit par ma capacité à gérer mes projets indépendamment, à prendre des initiatives et à m'adapter rapidement aux changements.
                    <br />
                    <br />Ces compétences sont essentielles dans le domaine de l'informatique, où les technologies évoluent rapidement. Elles me permettent d'innover, de proposer des solutions efficaces et de contribuer significativement aux objectifs de mon entreprise. Être débrouillard, curieux et autonome m'aide à rester à la pointe de l'innovation et à surmonter les défis techniques quotidiens.
                    <br />

                    <br /><h2 className='bold'>Mise en Place d'un Pipeline CI/CD chez Milango :</h2>
                    <br />Lors de mon alternance chez Milango, j'ai été chargé de développer un incubateur d'e-commerce, ce qui m'a conduit à mettre en place des pipelines de déploiement continu (CI/CD) en utilisant Bitbucket (Git) et AWS. Bien que je n'avais aucune expérience préalable dans ces deux domaines, j'ai réussi à apprendre rapidement sur le tas. J'ai passé plusieurs semaines à étudier la documentation et à suivre les meilleures pratiques recommandées par les experts du secteur. Grâce à ma débrouillardise et à ma curiosité, j'ai réussi à déployer une solution CI/CD efficace qui est toujours utilisée par l'entreprise plusieurs mois après sa mise en place. Ce projet m'a également offert l'occasion de travailler pour la première fois avec le cloud AWS, renforçant ainsi mon autonomie et ma capacité à m'adapter à de nouvelles technologies.
                    <br />
                    <br /><h2 className='bold'>Objectifs de Développement Futurs</h2>
                    <br />C’est entre autres grâce à mon expérience chez Milango que j'ai acquis une bonne maîtrise des pipelines CI/CD et des technologies cloud comme AWS. Cependant, je reconnais qu'il y a toujours des domaines à améliorer. Je souhaite approfondir mes connaissances en matière de sécurité des pipelines CI/CD et me familiariser avec d'autres services AWS pour diversifier mes compétences. Mon objectif est de devenir un expert reconnu dans le déploiement continu et les solutions cloud, capable de guider et d'inspirer mes collègues.
                    <br />
                    <br />En conclusion, ma capacité à être autonome, curieux et à résoudre des problèmes de manière créative m'a permis de surmonter des défis techniques complexes et de contribuer significativement aux projets de mon entreprise. Ces compétences sont essentielles dans le domaine de l'informatique, où les technologies évoluent rapidement. En continuant à développer ces qualités, je suis confiant de pouvoir atteindre de nouveaux sommets et inspirer ceux qui m'entourent à faire de même.

                </div>

            </div>
            <div className='articleProjectLink'>
                <div onClick={() => window.location.replace('/projects')}>Projet Milango</div>
            </div>
        </div>
    )
}
export default article0
